import SkillData from "./SkillData";
import "./Skills.css";

export default function About() {
    return (
        <section className="skills section" id="skills">
            <h2 className="section__title">Skills</h2>
            <span className="section__subtitle">Tech stacks</span>
            <div className="skills__container container grid hidden">


                <div className="skills__content">
                    <h3 className="skills__title">Language</h3>
                    <div className="skills__box">
                        <div className="skills__group">
                            <SkillData skillName="JavaScript" iconClass="bx bxl-javascript" />
                            <SkillData skillName="Python" iconClass="bx bxl-python" />
                            <SkillData skillName="C++" iconClass="bx bxl-c-plus-plus" />
                            <SkillData skillName="PHP" iconClass="bx bxl-php" />
                        </div>

                        <div className="skills__group">
                            <SkillData skillName="Dart" iconClass="bx bxl-google-cloud" />
                            <SkillData skillName="TypeScript" iconClass="bx bxl-typescript" />
                            <SkillData skillName="SQL" iconClass="bx bxs-data" />  
                            <SkillData skillName="Java" iconClass="bx bxl-java" />
                        </div>
                    </div>
                </div>


                <div className="skills__content">
                <h3 className="skills__title">Stacks</h3>
                    <div className="skills__box">
                        <div className="skills__group">
                            <SkillData skillName="HTML" iconClass="bx bxl-html5" /> 
                            <SkillData skillName="Flutter" iconClass="bx bxl-flutter" /> 
                            <SkillData skillName="Svelte.js" iconClass="bx bxl-react" /> 
                            <SkillData skillName="Tailwind" iconClass="bx bxl-tailwind-css" />
                            <SkillData skillName="Photoshop" iconClass="bx bxl-meta" />
                        </div>
                    
                        <div className="skills__group">
                            <SkillData skillName="CSS" iconClass="bx bxl-css3" /> 
                            <SkillData skillName="Firebase" iconClass="bx bxl-firebase" /> 
                            <SkillData skillName="Django" iconClass="bx bxl-django" /> 
                            <SkillData skillName="Git" iconClass="bx bxl-git" />  
                            <SkillData skillName="Figma" iconClass="bx bxl-figma" />  
                        </div>
                    </div>

                </div>

                
            </div>
        </section>
    );
}
