export default function Social() {
    return (
      <div className="home__social" id="home">
        <a
          href="https://www.facebook.com/nishan.k.52831/"
          className="footer__social-link"
          target="_black"
        >
          <i className="bx bxl-facebook"></i>
        </a>
        <a
          href="https://www.linkedin.com/in/nishan-bishwokarma-033574270/"
          className="footer__social-link"
          target="_black"
        >
          <i className="bx bxl-linkedin"></i>
        </a>
  
        <a
          href="https://www.instagram.com/nishan_thegod/"
          className="footer__social-link"
          target="_black"
        >
          <i className="bx bxl-instagram"></i>
        </a>
  
        <a
          href="https://github.com/nishansr/"
          className="footer__social-link"
          target="_black"
        >
          <i className="bx bxl-github"></i>
        </a>
      </div>
    );
  }
  