import { useState } from "react";
import "./Qualification.css";
import QualificationData from "./QualificationData";

export default function Qualification() {
  const [toggleState, setToggleState] = useState(true);

  const toggleTabWork = () => {
    setToggleState(true);
  };
  const toggleTabAcademy = () => {
    setToggleState(false);
  };

  return (
    <section className="qualification section" id="qualification">
      <h2 className="section__title">Experience</h2>
      <span className="section__subtitle">My timeline</span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
              toggleState === true
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={toggleTabWork}
          >
            <i className="uil uil-briefcase-alt qualification__icon"></i> Work
          </div>

          <div
            className={
              toggleState === false
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={toggleTabAcademy}
          >
            <i className="uil uil-graduation-cap qualification__icon"></i>{" "}
            Academy
          </div>
        </div>

        <div className="qualification__sections hidden">
          <div
            className={
              toggleState === true
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <QualificationData
              role="Flutter Developer"
              company="Reev InfoTech Pvt. Ltd."
              dateSpan="Nov 2023 - Present"
            />
            <QualificationData
              role="Freelancer"
              company="Home"
              dateSpan="Jan 2023 - Present"
              right={true}
            />
            <QualificationData
              role="Executive Member"
              company="CSITAN Pokhara"
              dateSpan="Nov 2022 - Present"
            />
          </div>

          <div
            className={
              toggleState === false
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <QualificationData
              role="Mount Annapurna Campus"
              company="Bsc. CSIT"
              dateSpan="Apr 2022 - Present"
            />
            <QualificationData
              role="Junior Citizens' Academy"
              company="+2, Science"
              dateSpan="Jul 2019 - Aug 2021"
              right={true}
            />
            <QualificationData
              role="Siddivinayak Boarding School"
              company="SEE"
              dateSpan="Apr 2012 - Mar 2019"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
