import "./Portfolio.css"
import PortfolioData from "./PortfolioData";

export default function Portfolio() {    
    return (
        <section className="services section" id="portfolio">
            <h2 className="section__title">Portfolio</h2>
            <span className="section__subtitle">Projects I have made</span>

            <div className="services__container container grid">
                <PortfolioData projectName="Civil Hero Mobile App (Flutter)" link="https://civilhero.reev-it.com/" iconClass="uil uil-apps services__icon" />
                <PortfolioData projectName="Brother's Portfolio" link="https://sajanfolio.vercel.app/" iconClass="uil uil-browser services__icon" />
                <PortfolioData projectName="Friend's Portfolio" link="https://ashishsubedi.com.np/" iconClass="uil uil-browser services__icon" />
                {/* <PortfolioData projectName="Data Cleansing Telecommunication Dataset" link="https://github.com/kevinwebtest/Telco-Data-Cleansing" iconClass="uil uil-database services__icon" /> */}
            </div>
        </section>
    );
}
